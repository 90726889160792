import React, {useState} from 'react'

const SectionBrochure = ({data}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const dataAcf = data['section__brochure'];

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        let formData = new FormData(e.target);

        try {
            const response = await fetch(process.env.REACT_APP_REST_URL + 'wp-json/gf/v2/forms/3/submissions', { 
                method: "POST", 
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            e.target.reset();
            setIsLoading(false);
            setIsSubmitted(true);
            setIsError(false);
        } catch (error) {
            console.error('Submission error:', error);
            setIsLoading(false);
            setIsError(true);
            setIsSubmitted(true);
        }
    }

    return (
        <section className='container mx-auto flex flex-col-reverse lg:flex-row gap-4 pt-6 lg:pt-20 pb-20'>
            <div className='content-and-form__container w-full lg:w-[45%] relative'>
                {isLoading && (
                    <div className='absolute z-10 inset-0 flex items-center justify-center'>
                        <svg className='animate-spin' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 12a9 9 0 1 1-6.219-8.56"/></svg>
                    </div>  
                )}

                <div className={`content-and-form__content transition-opacity duration-300 ${isLoading ? 'opacity-30' : ''}`}>
                    {dataAcf?.brochure_content && (
                        <div dangerouslySetInnerHTML={{__html: dataAcf?.brochure_content}} className='[&_h1]:lg:text-6xl [&_h1]:font-bold [&_h1]:mb-8 relative mb-10' />
                    )}

                    <form onSubmit={handleSubmit}>
                        {isSubmitted && (
                            <div className='mb-6 px-4 text-sm py-2.5 rounded-lg bg-green-100 border border-green-300 text-green-500'>
                                <p className='mb-0'>Bedankt voor het aanvragen van een brochure! </p>
                            </div>
                        )}

                        {isError && (
                            <div className='sr-only mb-6 px-4 text-sm py-2.5 rounded-lg bg-red-100 border border-red-300 text-red-500'>
                                <p className='mb-0'>Er is een fout opgetreden bij het verzenden van het formulier. Probeer het opnieuw. Of neem contact op via <a href="mailto:info@easyhaarden.nl">info@easyhaarden.nl</a></p>
                            </div>
                        )}

                        <div className='form-fields-wrapper relative grid grid-cols-1 sm:grid-cols-2 gap-y-6 sm:gap-y-10 gap-x-6'>
                            <input type="text" name="input_1" placeholder="Voornaam" required className='col-span-1 leading-loose border-b-2 border-t-0 border-x-0 focus:ring-0 focus:outline-0 focus:border-b-2 focus:border-gray-500 border-gray-500 bg-transparent py-2' />
                            <input type="text" name="input_3" placeholder="Achternaam" className='col-span-1 leading-loose border-b-2 border-t-0 border-x-0 focus:ring-0 focus:outline-0 focus:border-b-2 focus:border-gray-500 border-gray-500 bg-transparent py-2' />
                            <input type="email" name="input_5" placeholder="Email" required className='col-span-1 mb-4 sm:col-span-2 leading-loose border-b-2 border-t-0 border-x-0 focus:ring-0 focus:outline-0 focus:border-b-2 focus:border-gray-500 border-gray-500 bg-transparent py-2' />
                            <input type="submit" value="Brochure downloaden" className='min-w-[244px] cursor-pointer bg-black text-white px-4 py-3 text-center' />
                        </div>
                    </form>
                </div>
            </div>

            <div className='w-full sm:w-2/3 lg:w-[55%]'>
                <img 
                    src={dataAcf?.brochure?.url} 
                    alt={dataAcf?.brochure?.alt} 
                    className='w-full object-cover' 
                    width={dataAcf?.brochure?.width}
                    height={dataAcf?.brochure?.height}
                />
            </div>
        </section>
    )
}

export default SectionBrochure
import React from 'react';
import { Helmet } from "react-helmet";

import SectionHeroHome from '../partials/SectionHeroHome';
import SectionHeroAbout from '../partials/SectionHeroAbout';
import SectionSpacer from '../partials/SectionSpacer';
import SectionDuoNav from '../partials/SectionDuoNav';
import SectionTextSlider from '../partials/SectionTextSlider';
import SectionHeroBannerText from '../partials/SectionHeroBannerText';
import SectionHeroProduct from '../partials/SectionHeroProduct';
import SectionInfoProduct from '../partials/SectionInfoProduct';
import SectionVideoBanner from '../partials/SectionVideoBanner';
import SectionWysiwyg from '../partials/SectionWysiwyg';
import SectionStoriesSlider from '../partials/SectionStoriesSlider';

import { FetchApiData } from '../functions/helpers';
import Transitions from '../partials/animations/Transitions'

const Assortiment = ({ data }) => {
    let currentSlug = data;
    currentSlug = currentSlug.replace(/\//g, "");
    currentSlug = currentSlug.replace('haarden-assortiment', "");

    let page = FetchApiData('wp-json/wp/v2/haarden-assortiment?slug=' + currentSlug);



    return (
        (page !== false) &&

        <Transitions>
            <div className='page-container'>
                <Helmet>
                    <title>{page[0]?.yoast_head_json.title}</title>
                    <meta property='og:locale' content='nl_NL' />
                    <meta name="description" content={page[0]?.yoast_head_json.description} />
                    <meta property='og:title' content={page[0]?.yoast_head_json.og_title} />
                    <meta property='og:description' content={page[0]?.yoast_head_json.og_description} />
                    <meta property='og:url' content={page[0]?.yoast_head_json.og_url} />
                    {/* <meta property='article:modified_time' content= /> */}
                </Helmet>

                {page[0].type == 'haarden-assortiment' &&
                    <>
                        {(page[0].acf.flex_header.length > 0) && page[0].acf.flex_header.map((hero, index) =>
                            <div className='hero-container' key={index}>
                                {hero.acf_fc_layout == 'section__hero-home' && <SectionHeroHome data={hero} />}
                                {hero.acf_fc_layout == 'section__hero-about' && <SectionHeroAbout data={hero} />}
                                {hero.acf_fc_layout == 'section__hero_product' && <SectionHeroProduct data={hero} />}
                                {hero.acf_fc_layout == 'spacer' && <SectionSpacer data={hero} />}
                            </div>
                        )}

                        {(page[0].acf.flex_content.length > 0) && page[0].acf.flex_content.map((layout, index) =>
                            <div className='content-container' key={index}>
                                {layout.acf_fc_layout == 'section__duo-nav' && <SectionDuoNav data={layout} />}
                                {layout.acf_fc_layout == 'spacer' && <SectionSpacer data={layout} />}
                                {layout.acf_fc_layout == 'section__text_slider' && <SectionTextSlider data={layout} />}
                                {layout.acf_fc_layout == 'section__hero_banner_text' && <SectionHeroBannerText data={layout} />}
                                {layout.acf_fc_layout == 'section__info_product' && <SectionInfoProduct data={layout} />}
                                {layout.acf_fc_layout == 'wysiwyg' && <SectionWysiwyg data={layout} />}
                                {layout.acf_fc_layout == 'section__video_banner' && <SectionVideoBanner data={layout} />}
                                {layout.acf_fc_layout == 'section__stories_slider' && <SectionStoriesSlider data={layout} />}
                            </div>
                        )}
                    </>
                }
            </div>
        </Transitions>
    );
};

export default Assortiment;

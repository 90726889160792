import React from 'react';
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'

import '../assets/scss/section__hero-home.scss';

const SectionHeroHome = ({ data }) => {

    const dataAcf = data['section__hero-home'];

    // console.log(dataAcf)

    return (
        <section className='hero-home flex flex-col'>
            <div className="text-content container mx-auto flex items-start justify-between">
                <h1 className="big" dangerouslySetInnerHTML={{ __html: dataAcf.title }} />
                {dataAcf?.content &&

                    <div className="text">
                        <p>{dataAcf.content.text}</p>

                        {dataAcf.content?.link &&
                            <Link to={dataAcf.content.link.url} className="btn--link"> {dataAcf.content.link.title} </Link>
                        }
                    </div>

                }
            </div>

            <div className="hero-visual container mx-auto flex justify-end items-center">

                <div className="scrolldown flex flex-row justify-center items-center" id="scroll-down">
                    <span>SCROLL</span>
                    <div className="border-fff"></div>
                </div>

                {dataAcf?.image_video_check == true &&
                    <div className="hero-img">
                        <img src={dataAcf.hero_image.url} alt={dataAcf.hero_image.alt} />
                    </div>
                }
                {dataAcf?.image_video_check == false &&
                    <div className="hero-vid">
                        <ReactPlayer
                            url={dataAcf.video.url}
                            playing={true}
                            muted={true}
                            loop={true}
                        />
                    </div>
                }
            </div>
        </section>
    )
}

export default SectionHeroHome
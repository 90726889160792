import React from 'react'
import ReactPlayer from 'react-player'

import '../assets/scss/section_video-banner.scss'

const SectionVideoBanner = ({ data }) => {
    data = data.section__video_banner
    // console.log(data)
    return (
        <div className='video-banner container mx-auto'>
            <div className="video">
                <ReactPlayer
                    url={data.video.url}
                    playing={true}
                    muted={true}
                    loop={true}
                />
            </div>
        </div>
    )
}

export default SectionVideoBanner
import React, { useState } from 'react';

const InputField = ({ name, error, value, placeholder, onChangeHandler }) => {
  return (
    <div className="flex flex-col gap-1">
        <input
        type="text"
        name={name}
        value={value}
        className="border-b-2 border-t-0 border-x-0 focus:ring-0 focus:outline-0 focus:border-b-2 focus:border-gray-500 border-gray-500 bg-transparent py-2"
        placeholder={placeholder}
        onChange={(e) => onChangeHandler(e)}
        />
        {error && <div className="text-red-500 text-sm">{error}</div>}
    </div>
  );
}

export default InputField;

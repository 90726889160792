import "../assets/scss/section__hero_contact.scss"
import WarrantyForm from "./formData"

const SectionHeroContact = ({ data }) => {
    data = data.section__contact
    return (
        <section className="hero-contact">
            <div className="wrap container mx-auto flex flex-row items-center">
                <div className="content">
                    <h1>{data?.content.title}</h1>
                    <p>{data?.content.intro}</p>

                    {data.content?.left_text && data.content?.left_right && (
                        <div className="cols flex flex-row">
                            <div
                                className="col"
                                dangerouslySetInnerHTML={{
                                    __html: data.content.left_text,
                                }}
                            ></div>
                            <div
                                className="col"
                                dangerouslySetInnerHTML={{
                                    __html: data.content.left_right,
                                }}
                            ></div>
                        </div>
                    )}
                    <WarrantyForm />
                    {/* {data.content?.full_width_text && (
                        <div />
                    )} */}
                </div>
                <img
                    src={data?.hero_image.url}
                    alt={data?.hero_image.alt}
                    className="hero-img"
                />
            </div>
        </section>
    )
}

export default SectionHeroContact

import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import svgDownload from '../assets/images/download.svg';
import menuClose from '../assets/images/exit-cross.svg';
import svgArrow from '../assets/images/triangle.svg';
import '../assets/scss/FilterCheckbox.scss';
import '../assets/scss/section__downloads.scss';
import { FetchApiData } from '../functions/helpers';
gsap.registerPlugin(ScrollToPlugin);

// const handlePopup = (item) => {
//     // console.log(item)
//     const div = document.createElement('div')
//     div.classList.add('popup-model')
//     div.id = 'popup-model'

//     if (item.optional == 'pdf') {
//         // niks hier want je download de pdf
//         return
//     }
//     if (item.optional == 'video') {
//         div.innerHTML = "<div><h3>" + item.title + "</h3><video controls><source src=" + item.video.url + " type='video/mp4'></video><div id='exit-popup'><img src=" + menuClose + " ></div ></div > ";
//     }
//     if (item.optional == 'text') {
//         var title = item.title;
//         var onderwerp = item.onderwerp;
//         var haard = item.haard;
//         var text = item.text;
//         div.innerHTML =
//             "<div><h3>" + title + "</h3><h4>" + onderwerp + "</h4><h4>" + haard + "</h4><span>" + text + "</span><div id='exit-popup' ><img src=" + menuClose + " ></div></div>";
//     }

//     //apend correcte kind
//     console.log(div)
//     document.body.appendChild(div);
//     document.getElementById('exit-popup').addEventListener('click', function () {
//         document.body.removeChild(document.getElementById('popup-model'));
//     });
//     return
// }

//main component
const SectionDownloads = ({ data, page }) => {
	const [filterShown, setFilterShown] = useState(false);
	const gridHook = useRef(null);

	// console.log(page)
	let subPages = FetchApiData(
		'wp-json/kennisbank-options/children?ID=' + page[0].id
	);
	var dataObjects = subPages;
	const [filter, setFilter] = useState({
		optional: [],
		onderwerp: [],
		haard: [],
	});

	useEffect(() => {
		gsap.to(window, {
			duration: 0.5,
			ease: 'power2.out',
			scrollTo: gridHook.current.offsetTop - 50,
		});
	}, [filter]);

	function handleFilter(item) {
		let currentFilterValues = filter;

		for (const key in currentFilterValues) {
			if (key == item.target.id.split('-')[0]) {
				if (filter[key].includes(item.target.name)) {
					setFilter({
						...filter,
						[key]: filter[key].filter(
							(filterItem) => filterItem !== item.target.name
						),
					});
				} else {
					setFilter({
						...filter,
						[key]: [...filter[key], item.target.name],
					});
				}
			}
		}
	}

	const filteredItems = () => {
		let objects = [];
		dataObjects &&
			dataObjects.map((item) => {
				for (const key in filter) {
					if (filter[key].length > 0) {
						if (!filter[key].includes(item.acf[key])) {
							return;
						}
					}
				}
				objects.push(item);
			});

		if (objects.length || isFilterSet()) {
			return objects;
		} else {
			return dataObjects;
		}
	};

	function isFilterSet() {
		for (const key in filter) {
			if (filter[key].length > 0) {
				return true;
			}
		}
		return false;
	}

	return (
		<section className="downloads grid grid-cols-12 mb-32" id="downloads">
			<button
				onClick={() => setFilterShown((prev) => !prev)}
				className={
					'col-span-12 bg-black py-2 text-center mb-12 text-white font-bold block lg:hidden'
				}
			>
				Filter
			</button>
			<div
				className={
					'downloads-header col-span-12 lg:col-span-3 lg:block ' +
					(filterShown ? '' : 'hidden')
				}
			>
				{dataObjects && (
					<HeaderContent
						data={dataObjects}
						handleFilter={handleFilter}
					/>
				)}
				<div ref={gridHook} />
			</div>
			<div className="downloads-content col-span-12 lg:col-span-9">
				{/* display grid */}
				<DisplayDownloads items={filteredItems()} page={page} />
			</div>
		</section>
	);
};

const DisplayDownloads = ({ items, page }) => {
	return (
		<div className="downloads-grid-content-grid  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
			{items &&
				items.map((item, index) => (
					<Link
						to={`/kennisbank/${page[0].slug}/${item.post_name}`}
						key={index}
						className="grid-item"
					>
						<img src={item?.thumbnail} />
						<div className="grid-item-content">
							<div className="content-main">
								<h3>{item?.post_title}</h3>
								<p>{item?.acf?.onderwerp}</p>
							</div>
						</div>
					</Link>
				))}
		</div>
	);
};

//load in onderwerpen && haarden && type
const HeaderContent = ({ data, handleFilter }) => {
	console.log('data', data);
	const newOnderwerpen = [...data, ...data]
		.map((item) => item.acf.onderwerp)
		.filter((item, index, array) => array.indexOf(item) === index);
	const haarden = [...data, ...data]
		.map((item) => item.acf.haard)
		.filter((item, index, array) => array.indexOf(item) === index);
	const type = [...data, ...data]
		.map((item) => item.acf.optional)
		.filter((item, index, array) => array.indexOf(item) === index);

	// newOnderwerpen = newOnderwerpen.filter(filterItem => filterItem !== )
	return (
		<div className="downloads-header-content">
			<div className="radio-container">
				<h3>Type</h3>
				{type &&
					type.map((item, index) => (
						<div key={index}>
							<span className="checkbox">
								<input
									type="checkbox"
									name={item}
									id={'optional-' + item}
									onChange={(e) => handleFilter(e)}
								/>
								<label htmlFor={'optional-' + item}>
									{item}
								</label>
							</span>
						</div>
					))}
			</div>
			<div className="radio-container">
				<h3>Onderwerpen</h3>
				{newOnderwerpen &&
					newOnderwerpen.map((item, index) => {
						return (
							<div key={index}>
								<span className="checkbox">
									<input
										type="checkbox"
										name={item}
										id={'onderwerp-' + item}
										onChange={(e) => handleFilter(e)}
									/>
									<label htmlFor={'onderwerp-' + item}>
										{item}
									</label>
								</span>
							</div>
						);
					})}
			</div>
			<div className="radio-container">
				<h3>Haarden</h3>
				{haarden &&
					haarden.map((item, index) => (
						<div key={index}>
							<span className="checkbox">
								<input
									type="checkbox"
									name={item}
									id={'haard-' + item}
									onChange={(e) => handleFilter(e)}
								/>
								<label htmlFor={'haard-' + item}>{item}</label>
							</span>
						</div>
					))}
			</div>
		</div>
	);
};

export default SectionDownloads;

import * as React from 'react'
import { Helmet } from "react-helmet";

import Navbar from './partials/Navbar';
import Pages from './pages/Pages';
import Assortiment from './pages/Assortiment';
import Assortimenten from './pages/Assortimenten';
import Stories from './pages/Stories';
import Story from './pages/Story';
import Footer from './partials/Footer';
import Kennisbank from './pages/Kennisbank';
import KennisbankSingle from './pages/KennisbankSingle';

import { FetchApiData } from './functions/helpers';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import ScrollToTop from './partials/animations/ScrollToTop';

import {
    Routes,
    Route
} from "react-router-dom";

import 'normalize.css';
import './App.css';
import KennisbankSingleChild from './pages/KennisbankSingleChild';


const App = () => {

    // Fetch API data for ACF options fields header and footer
    const optionsAcf = FetchApiData('wp-json/acf/v3/options/options');

    // Get current location &
    // based on path set componentName with which we decide what component to mount
    // add location.pathname to location var to send to component for a specific api request for that slug.
    let location = useLocation();
    // let location_animate = useLocation();
    let componentName;

    function getLocationSlug() {

        let newLocation = location;

        if (newLocation.pathname == '/') {
            newLocation = 'home';
            componentName = 'Pages';

        } else {
            newLocation = newLocation.pathname;
            componentName = 'Pages';
    
            if (newLocation === '/haarden-assortiment/' || newLocation === '/haarden-assortiment') {
                componentName = 'Assortimenten';
            } else if (newLocation.includes('haarden-assortiment')) {
                componentName = 'Assortiment';
            }
    
            if (newLocation === '/stories/' || newLocation === '/stories') {
                componentName = 'Stories';
            } else if (newLocation.includes('stories')) {
                componentName = 'Story';
            }
    
            if (newLocation === '/kennisbank/' || newLocation === '/kennisbank') {
                componentName = 'Kennisbank';
            } else if (newLocation.includes('kennisbank')) {
                componentName = 'KennisbankSingle';
    
                // if is child page
                if (newLocation.split('/').pop()) {
                    componentName = 'KennisbankSingleChild';
                }
            }
        }

        return newLocation;
    }

    // Router
    return (
        <div className="App">

            {(optionsAcf !== false) && <Navbar data={optionsAcf.acf} />}

            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <main>
                <ScrollToTop>
                    <AnimatePresence exitBeforeEnter> 
                        <Routes location={location} key={location.pathname}>
                            <Route path={'/haarden-assortiment'} element={<Assortimenten data={getLocationSlug()} />} />
                            <Route path={'/haarden-assortiment/*'} element={<Assortiment data={getLocationSlug()} />} />
                            <Route path={'/kennisbank/*'} element={<KennisbankSingle data={getLocationSlug()} />} />
                            <Route path={'/kennisbank/kenniscentrum/:page'} element={<KennisbankSingleChild data={getLocationSlug()} />} />
                            <Route path={'/kennisbank'} element={<Kennisbank data={getLocationSlug()} />} />
                            <Route path={'/stories/*'} element={<Story data={getLocationSlug()} />} />
                            <Route path={'/stories'} element={<Stories data={getLocationSlug()} />} />
                            <Route path={'/*'} element={<Pages data={getLocationSlug()} />} />
                        </Routes>
                        </AnimatePresence>
                </ScrollToTop>
            </main>

            {(optionsAcf !== false) && <Footer data={optionsAcf?.acf?.footer} />}
        </div>
    )
}

export default App
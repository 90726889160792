import React from 'react'
import { Link } from 'react-router-dom'
import '../assets/scss/footer.scss'

const Footer = ({ data }) => {

    return (
        <footer className='footer container mx-auto'>
            <div className="footer-head flex flex-row items-center justify-between">
                <div className="logo">
                    <img src={data.logo.url} alt={data.logo.alt} />
                </div>
                <div className="border-orange"></div>
            </div>
            <div className="footer-content flex flex-row justify-between items-start">
                <div className="col nav-big flex flex-col">
                    {data.sitemap_big.links.map((item, index) =>
                        <Link to={item.link.url} key={index}>{item.link.title}</Link>
                    )}
                </div>
                <div className="col nav-small flex flex-col">
                    {data.sitemap_small.links.map((item, index) =>
                        <Link to={item.link.url} key={index} className='last:!font-[700] last:!underline'>{item.link.title}</Link>
                    )}
                </div>
                <div className="col nav-info">
                    <div className="social-row flex flex-row">
                        {data.socials.links.map((item, index) =>
                            <Link to={item.link} key={index}>
                                <img src={item.icon.url} alt={item.icon.alt} />
                            </Link>
                        )}
                    </div>
                    <div className="info flex flex-col">
                        <div className="top flex flex-col">
                            {data.socials.email_and_tel.map((item, index) =>
                                <a href={item.link.url} target={item.link.target} key={index}>{item.link.title}</a>
                            )}
                            <span>{data.socials.openingstijden}</span>
                        </div>
                        <div className="bottom flex flex-col">
                            <span>{data.socials.kvk}</span>
                            <span>{data.socials.btw}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-foot flex flex-row justify-between items-center">
                <span>Site by: <Link to="https://booomdigital.nl/" target='_blank' rel="noreferrer">BOOOM Digital</Link> </span>
                <Link to="/privacy-voorwaarden/">Privacy voorwaarden</Link>
            </div>
        </footer>
    )
}

export default Footer
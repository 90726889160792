import React from 'react'

import '../assets/scss/section_info-product.scss'
import downloadIcon from '../assets/images/download.svg'

const SectionInfoProduct = ({ data }) => {
    data = data.section__info_product;

    return (
        <div className='info-product container mx-auto flex flex-row justify-between'>
            <aside className="sidebar flex flex-col">
                {data.sidebar.kenmerken !== false &&
                    <>
                        <h3>{data.sidebar.kenmerken_title}</h3>
                        {data.sidebar.kenmerken.map((kenmerk, index) =>
                            <div className="item" key={index}>
                                <span className="kenmerk">
                                    {kenmerk.kenmerk}
                                </span>
                            </div>
                        )}
                    </>
                }


                {data.sidebar.geleverd_met_items !== false &&
                    <>
                        <h3 className='second-h3'>{data.sidebar.geleverd_met_title}</h3>
                        {data.sidebar.geleverd_met_items.map((items, index) =>
                            <div className="item" key={index}>
                                <span className="kenmerk" dangerouslySetInnerHTML={{__html: items.geleverd_met_item}}/>
                            </div>
                        )}
                    </>
                }

                {data.sidebar.downloads !== false &&
                    <>
                        <h3 className='second-h3'>{data.sidebar.download_title}</h3>
                        {data.sidebar.downloads.map((download, index) =>
                            <div className="item" key={index}>
                                <a href={download.download.url} className="download flex flex-row items-center">
                                    <img src={downloadIcon} alt="" />
                                    <span>
                                        {download.download.title}
                                    </span>
                                </a>
                            </div>
                        )}
                    </>
                }

            </aside>
            <div className="content" dangerouslySetInnerHTML={{ __html: data.content.product_info }}></div>
        </div>
    )
}

export default SectionInfoProduct
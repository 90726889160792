import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FetchApiData } from '../functions/helpers';


import '../assets/scss/section_product-slider.scss'

const SectionProductSlider = ({ data }) => {
    const [slideContent, setSlideContent] = useState(0);
    const slideFade = useRef();
    const borderGrow = useRef();

    data = data.section__product_slider


    useEffect(() => {
        slideFade.current.animate({ opacity: [0, 1] }, 500)
        borderGrow.current.animate({ width: [0, '220px'] }, 500)
    }, [slideContent]);

    function getSrc(id, index) {
        let imgUrl;
        imgUrl = FetchApiData('wp-json/wp/v2/haarden-assortiment?id=' + id + '&_embed=true');

        const matchingImg = imgUrl ? imgUrl?.filter((product) => product?.id === id)?.[0]?.fimg_url : null

        if (matchingImg) {
            let imgSrc = matchingImg
            return imgSrc;
        }
    }

    return (
        <div className='product-slider container mx-auto flex flex-row justify-between items-center'>
            <div className="slider">
                {data.product_slider_repeater.length > 0 && data.product_slider_repeater.map((item, index) =>
                    <div key={index}>
                        {slideContent == index &&
                            <div className="slider-item" ref={slideFade}>
                                {/* <img src={getSrc(item.product_slider_repeater_post_obj.ID, index)} alt='' /> */}
                                <img src={getSrc(item.product_slider_repeater_post_obj.ID, index)} alt='' />
                            </div>
                        }
                    </div>
                )}
            </div>
            <div className="col-content">
                <h2>{data.product_slider_title}</h2>
                <p>{data.product_slider_intro_text}</p>

                <div className="slider-nav">
                    {data.product_slider_repeater.length > 0 && data.product_slider_repeater.map((item, index) =>
                        <div className={(slideContent == index) && 'slider-nav-item active' || 'slider-nav-item'}
                            key={index}
                            onClick={() => setSlideContent(index)}
                        >
                            <span>{item.product_slider_repeater_post_obj.post_title}</span>

                            {slideContent === index &&
                                <div className="active-border" ref={borderGrow}></div>
                            }
                        </div>
                    )}
                </div>

                <Link className='readmore' to="/haarden-assortiment/">Bekijk hele assortiment</Link>
            </div>
        </div>
    )
}

export default SectionProductSlider
import React from 'react';
import '../../assets/scss/FilterCheckbox.scss';

const FilterCheckbox = ({ filterState, filterItems, radio }) => {
	const handleClickCheckbox = (item) => {
		if (filterState.filter.includes(item)) {
			radio ||
				filterState.setFilter(
					filterState.filter.filter((i) => i !== item)
				);
		} else {
			filterState.setFilter(
				radio ? [item] : [...filterState.filter, item]
			);
		}
	};

	return (
		<section className="filter w-full">
			{filterItems.map((item, index) => (
				<div className="filter__item flex items-center" key={index}>
					<input
						type="checkbox"
						id={item}
						onChange={() => handleClickCheckbox(item)}
						checked={filterState.filter.includes(item)}
					/>
					<label className="hover:cursor-pointer" htmlFor={item}>
						{item}
					</label>
				</div>
			))}
		</section>
	);
};

export default FilterCheckbox;
